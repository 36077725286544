


























import { defineComponent } from "@vue/composition-api";
import { getActiveProjects } from "@/api/projectService";
import { userModule } from "@/store/modules/user";
import ClosedProjectCardCustomer from "./ClosedProjectCardCustomer.vue";
import OpenProjectCardCustomer from "./OpenProjectCardCustomer.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  components: { OpenProjectCardCustomer, ClosedProjectCardCustomer },
  name: "MyProjects",
  setup() {
    const { isLoading, data: projects } = useAsyncData(() =>
      getActiveProjects("customer", userModule.state.userId)
    );

    return {
      isLoading,
      projects,
    };
  },
});
