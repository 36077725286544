













































import { ActiveProject } from "@/interfaces/Project";
import Moment from "@/services/moment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import ReadableCategories from "@/components/shared/ReadableCategories.vue";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import StartOnlineSessionButton from "@/components/StartOnlineSessionButton.vue";

export default defineComponent({
  components: { StartOnlineSessionButton, ReadableCategories, ProfilePhoto },
  name: "ClosedProjectCardCustomer",
  props: {
    project: {
      type: Object as PropType<ActiveProject>,
      required: true,
    },
  },
  setup(props) {
    const createdReadable = computed(() => {
      return Moment.unix(props.project.created).format("Do MMMM");
    });

    const sellerRegisterDateReadable = computed(() => {
      if (props.project.seller && props.project.seller.user.registerDate < Moment().subtract(1, "year").unix()) {
        return Moment.unix(props.project.seller.user.registerDate).format("Do MMMM YYYY");
      }
      return false;
    });

    return {
      createdReadable,
      sellerRegisterDateReadable
    };
  }
});
