










import { userModule } from "@/store/modules/user";
import { defineComponent } from "@vue/composition-api";
import MyProjects from "@/components/customer/my-projects/MyProjects.vue";

export default defineComponent({
  components: { MyProjects },
  name: "CustomerDashboard",
  setup() {
    function getTimeOfDayMessage() {
      let timeOfDay = "";
      const hour = new Date().getHours();

      if (hour === 3 && new Date().getMinutes() === 14) {
        timeOfDay = "Klokken er π!";
      }
      else if (hour < 4) {
        timeOfDay = "Leggetid, " + userModule.state.profile.firstName + "!";
      }
      else if (hour < 6) {
        timeOfDay = "Imponerende tidlig oppe, " + userModule.state.profile.firstName + "!";
      }
      else if (hour < 9) {
        timeOfDay = "God morgen, " + userModule.state.profile.firstName;
      }
      else if (hour < 12) {
        timeOfDay = "God formiddag, " + userModule.state.profile.firstName;
      }
      else if (hour < 15) {
        timeOfDay = "God dag, " + userModule.state.profile.firstName;
      }
      else if (hour < 18) {
        timeOfDay = "God ettermiddag, " + userModule.state.profile.firstName;
      }
      else if (hour >= 18) {
        timeOfDay = "God kveld, " + userModule.state.profile.firstName;
      }
      return timeOfDay;
    }

    return {
      getTimeOfDayMessage,
    };
  },
});
